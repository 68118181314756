import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";

import i18n from "i18next";
import {
  FooterSection,
  NavLink,
  Extra,
  LogoContainer,
  Para,
  Chat,
  Language,
  LanguageSwitch,
  LanguageSwitchContainer,
} from "./styles";

const Footer = ({ t }: any) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="space-between">
            <Col lg={9} md={9} sm={12} xs={12}>
              <Language>{t("Contact")}</Language>
              <Para>{t("Email")}: raymond.liu@cerberus-solution.com</Para>
              <Para>{t("Tel")}: (+886) 909 782 882</Para>

              <Para>
                {t(`Any question? Feel free to reach out.`)}
              </Para>
              <a href="mailto:raymond.liu@cerberus-solution.com">
                <Chat>{t(`Let's Chat`)}</Chat>
              </a>
            </Col>
            <Col lg={9} md={9} sm={12} xs={12}>
              <Language>{t("Address")}</Language>
              <Para>{t("Address Street")}</Para>
              <Para>{t("Address District")}</Para>
              <Para>{t("Address City Country")}</Para>
            </Col>
            <Col lg={5} md={5} sm={12} xs={12}>
              <Language>{t("Language")}</Language>
              <LanguageSwitchContainer>
                <LanguageSwitch onClick={() => handleChange("en")}>
                  <SvgIcon
                    src="united-states.svg"
                    aria-label="homepage"
                    width="30px"
                    height="30px"
                  />
                </LanguageSwitch>
                <LanguageSwitch onClick={() => handleChange("tw")}>
                  <SvgIcon
                    src="taiwan.svg"
                    aria-label="homepage"
                    width="30px"
                    height="30px"
                  />
                </LanguageSwitch>
              </LanguageSwitchContainer>
            </Col>
          </Row>
        </Container>
      </FooterSection>
      <Extra>
        <Container border={true}>
          <Row
            justify="space-between"
            align="middle"
            style={{ paddingTop: "1rem" }}
          >
            <NavLink to="/">
              <LogoContainer>
                <SvgIcon src="logo192.svg" width="64px" height="64px" />
                {t("Cerberus Solution")}
              </LogoContainer>
            </NavLink>
          </Row>
          <Row
            justify="space-between"
            align="middle"
          >
            <Col lg={9} md={9} sm={12} xs={12}>
              Copyright © 2023 {t("Cerberus Solution Limited.")}
            </Col>
            <Col lg={9} md={9} sm={12} xs={12}>
              <NavLink to="/privacy-policy">
                <Para>{t("Privacy Policy")}</Para>
              </NavLink>
            </Col>
          </Row>
          <Row
            justify="space-between"
            align="middle"
          >
            <Col lg={9} md={9} sm={12} xs={12}>
              <NavLink to="/">
                <Para>Web designed by {t("Cerberus Solution Limited.")}</Para>
              </NavLink>
            </Col>
            <Col lg={9} md={9} sm={12} xs={12}>
              <NavLink to="/site-map">
                <Para>{t("Site Map")}</Para>
              </NavLink>
            </Col>
          </Row>
        </Container>
      </Extra>
    </>
  );
};

export default withTranslation()(Footer);
